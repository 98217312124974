'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

require('core-js/modules/es6.object.assign');

require('core-js/modules/es6.array.from');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Parallax = function () {
  function Parallax(selector, options) {
    _classCallCheck(this, Parallax);

    this.lastPosition = -1;

    // Establish default settings
    this.settings = Object.assign({
      speed: 0.2
    }, options);

    if (typeof selector === 'string') {
      this.elems = Array.from(document.querySelectorAll(selector));
    } else if (Array.isArray(selector)) {
      this.elems = selector;
    } else {
      this.elems = [selector];
    }
  }

  _createClass(Parallax, [{
    key: 'updatePosition',
    value: function updatePosition() {
      var _this = this;

      this.elems.forEach(function (elem) {
        var offset = elem.getBoundingClientRect().top + _this.lastPosition;
        var yPosition = Math.round((offset - _this.lastPosition) * _this.settings.speed);

        // Apply the y-axis transform
        elem.style.transform = 'translate3d(0, ' + yPosition * -1 + 'px, 0)'; // eslint-disable-line
      });
    }
  }, {
    key: 'animate',
    value: function animate() {
      var _this2 = this;

      // If the offset position hasn't changed, skip this frame
      if (this.lastPosition === window.pageYOffset) {
        window.requestAnimationFrame(function () {
          _this2.animate();
        });

        return false;
      }

      // Save the new offset position
      this.lastPosition = window.pageYOffset;

      this.updatePosition();

      return window.requestAnimationFrame(function () {
        _this2.animate();
      });
    }
  }]);

  return Parallax;
}();

exports.default = Parallax;
module.exports = exports['default'];
